.agreement-wrap {
    &.order-detail-body {
        width: 1000px;
        margin: 0 auto;
        background: $colorWhite;
        min-height: 640px;
        display: flex;
        align-items: center;
        padding: 60px 30px;
        font-size: 18px;
        transform: translateY(25%);

        &--non-center {
            display: block;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;

            padding: 38px 28px 30px 28px;

            .agreement-box {
                padding: 0;
                display: block;
            }
        }

        &--narrow {
            width: 550px;
            max-height: 480px;
            min-height: 480px;
            overflow-y: auto;
            transform: translateY(calc(100% - (480px / 1.5)));
            display: block;
            padding: 38px 20px 30px 28px;
            overflow: hidden;
            border-radius: 3px;

            &--400 {
                width: 400px;
            }

            &--center {
                transform: unset;
            }

            
            .agreement-box {
                max-height: 480px;
                min-height: 480px;
                padding: 0;
                display: block;
                overflow: hidden;
                box-sizing: border-box;
            }
            
            &--auto-height {
                min-height: auto;

                .agreement-box {
                    min-height: auto;
                }
            }

            &--auto-max-height {
                max-height: none;

                .agreement-box {
                    max-height: none;
                }
            }
        }
    }
}

.agreement-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    width: 100%;
    padding: 0 60px;
    box-sizing: content-box;

    .agreement-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: $fontSizeL;

        span {
            color: $colorBright1_09;
        }

        button {
            margin-left: 20px;
        }
    }

    .agreement-container {
        &__header {
            font-size: $fontSizeXL;
            @include fontFamilyMedium;

            margin-bottom: 24px;
        }

        .agreement-container-content {
            max-height: 310px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
                height: 310px;
                background: #F3F3F3;
                border-radius: 12px;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 12px;
                background: #D3D3D3;
                height: 29px;
            }

            p {
                line-height: 24px;
                font-size: $fontSizeL;
            }

            .agreement-item {
                margin-bottom: 20px;

                padding-right: 24px;
                
                &__header {
                    font-size: $fontSizeL;
                    @include fontFamilyBold;

                    margin-bottom: 4px;
                }

                &__list {
                    padding-left: 8px;
                    margin: 12px 0;
                    font-size: $fontSizeL;

                    line-height: 24px;

                    li {
                        display: flex;

                        margin-bottom: 8px;

                        &:before {
                            content: "\2022";
                            color: $colorText;
                            display: block;
                            width: 8px;
                            height: 8px;
                            margin-right: 16px;
                          }
                    }
                }
            }
        }
    }

    .agreement-item {
        margin-bottom: 50px;
        // width: 800px;

        .notice {
            color: rgba(59, 70, 78, 0.50);
        }
    }

    i {
        font-style: italic;
    }

    h2 {
        margin-bottom: 15px;
        @include fontFamilyBold;
        font-size: 30px;
    }

    h3 {
        margin-bottom: 20px;
    }

    p {
        line-height: 30px;
    }

    .mb20 {
        margin-bottom: 20px !important;
    }

    .list {
        margin-bottom: 15px;

        li {
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;
            line-height: 26px;

            &:before {
                content: "";
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $color08;
                position: absolute;
                left: 0;
                top: 9px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .numeric {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
        margin-top: 8px;

        li {
            padding-left: 15px;
            position: relative;
            margin-bottom: 30px;
            line-height: 20px;

            h3 {
                @include fontFamilyBold;
                margin-bottom: 8px;
            }

            li {
                padding-left: 25px;
                @include fontFamilyRegular;
                margin-bottom: 8px;

                &:before {
                    @include fontFamilyRegular;
                    top: 0;
                    font-size: 14px;
                    color: $color08;
                }

                li {
                    padding-left: 40px;
                    @include fontFamilyRegular;

                    li {
                        padding-left: 55px;
                    }
                }
            }

            &:before {
                counter-increment: li;
                content: counters(li,".") ". ";
                position: absolute;
                left: 0;
                font-size: 16px;
                @include fontFamilyBold;
            }
        }

        h3 {
            display: inline-block;
        }
    }

    table {
        border-spacing: 0;
        margin-bottom: 20px;
        border-collapse: collapse;
        width: 100%;

        td {
            border: 1px solid $color06;
            padding: 10px;
            line-height: 30px;

            &:first-child {
                text-align: center;
            }

            p {
                margin-bottom: 10px;
            }
        }

        .list {
             margin-bottom: 0;
        }

        th {
            background-color: $color05;
            border: 1px solid $color06;
            padding: 10px;
        }
    }
}
