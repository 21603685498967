@import "./croppie";

.order-tab {
  &.tab-agreement {
    width: 100%;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin-left: 0;
    z-index: 3;
    position: fixed !important;
    background: transparent;
    animation: none !important;
  }

  .person-info-box {
    .menu {
      padding: 8px;

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .check-empty {
        opacity: 0;
        transition: opacity $transitionTime;
        margin-left: 10px;
      }

      li {
        margin-bottom: 0;
        &.active {
          .check-empty {
            opacity: 1;
          }
        }
      }
    }

    .person-info-head {
      margin-bottom: 15px;

      .writer-group {
        background-size: 20px;
        min-height: 20px;
        padding-left: 30px;
        display: block;
        padding: 10px 0 10px 30px;
      }

      .writer-info {
        color: $color09;
        font-size: $fontSizeMd;
        letter-spacing: 0.1px;
        border-bottom: 1px solid $color12;
        margin-bottom: 20px;

        i {
          color: $color07;
          display: inline-block;
          margin: 0 3px;
        }

        .i-question {
          background-image: url("./img/icons/question.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
          padding-left: 20px;
        }
      }

      @include person-common-info;

      h4 {
        @include fontFamilyBold;
        letter-spacing: 0.1px;
        color: $color07;
        margin-bottom: 10px;
      }

      .person-contact-info {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px $color06;
        border-radius: $borderRadius;
        padding: 9px;
        font-size: $fontSizeSm;

        .person-contact-info-item {
          display: flex;
          align-items: center;
        }

        .contact-info-email,
        .contact-info-skype,
        .contact-info-phone {
          .lnk-dropdown,
          .text-narrow-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 120px;
            text-align: left;
          }

          .text-danger {
            margin-top: 5px;
            display: inline-block;
          }
        }

        .contact-info-password {
          width: 68px;

          .dropdown-content {
            right: -27px;
            left: auto;
          }
        }

        .contact-info-email {
          .dropdown-content {
            left: -10px;
          }
        }

        .dropdown {
          height: auto;

          .btn-block {
            padding: 7px 10px;
          }
        }

        .dropdown-content {
          padding: 10px;
        }

        .devider {
          margin: 0 4px;
        }

        .btn-small {
          line-height: 17px;
          margin-left: 5px;
        }

        .skype {
          margin-right: 3px;
          position: relative;
        }

        .check-fill.success {
          margin-left: 5px;
        }
      }
    }

    .person-info {
      color: $color08;
      letter-spacing: 0.1px;

      b {
        color: $color09;
      }

      i {
        color: $color07;
      }
    }

    .person-info-item {
      @include border-btn;
      position: relative;
      margin-bottom: 15px;

      &.is-error {
        border: 1px solid $colorBright2_10;
      }

      > div:first-child {
        padding: 11px 15px;
        cursor: pointer;
      }

      .ReactCollapse--content {
        padding: 0 15px 15px 15px;
      }

      h3 {
        @include fontFamilyBold;
        color: $colorText;
        font-size: $fontSizeMd;
      }

      .btn {
        &:disabled,
        &.disabled {
          cursor: not-allowed;
          background-color: #eceff1;
          border: solid 1px #dadee2;
          color: rgba(59, 70, 78, 0.35);
        }
      }

      .arrow-down:not(.dropdown-arrow) {
        transform: rotate(-45deg);
        position: absolute;
        right: 15px;
        top: 15px;
        border-color: #c0c7ce;
        cursor: pointer;

        &.active {
          transform: rotate(45deg);
        }
      }

      .bg-context {
        margin-bottom: 22px;
      }

      .radio-icon {
        margin-top: -17px;
      }

      .payment-info-box {
        .payment-info--dropdown {
          margin-bottom: 20px;

          .arrow-down {
            transform: rotate(45deg);
            top: 10px;
          }

          b {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      .payment-info {
        padding: 16px 20px;
        border-top: 1px solid rgba(230, 232, 235, 1);

        &--no-border {
          border-top: none;
        }

        &.active {
          border-radius: 3px;
          background-color: #ebf7ff;
          position: relative;
          margin-bottom: 8px;

          &:before {
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            background-color: #8dbdff;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .img-payment {
          display: block;

          &.pending {
            filter: gray;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            opacity: 0.8;
          }
          &.verified {
            opacity: 0.8;
          }
        }

        .no-country-value {
          color: rgba(192, 199, 206, 1)!important;
        }

        .input-text {
          padding: 7px 16px;
          font-size: 14px;
          line-height: 16px;
        }

        .btn-bright-success {
          margin-left: 20px;
          width: 87px;
        }

        .warning-banner {
          padding: 12px 8px;
          border: 1px solid rgba(253, 166, 100, 1);
          background-color: rgba(247, 249, 250, 1);
          border-radius: 3px;
          color: rgba(59, 70, 78, 1);
          text-align: center;
          margin-bottom: 12px;
          font-size: 12px;
        }
      }

      .additional-info {
        margin: 10px 0 20px 190px;

        .link-item {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .input-box {
          align-self: flex-end;
        }
      }

      .status-box {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;

        span {
          color: $colorText;
        }

        .watch,
        .error,
        .check-fill {
          margin-right: 5px;
          top: 1px;
        }

        a {
          border-bottom: 1px dashed $colorAction09;
          display: inline;

          &:hover {
            text-decoration: none;
          }
        }

        .tooltip-content {
          right: 0;
          bottom: 100%;
          top: auto;
          display: block;

          ul > li {
            line-height: 1.54;
            letter-spacing: 0.1px;
          }

          b {
            font-size: $fontSizeMd;
            margin-bottom: 8px;
            display: inline-block;
          }
        }

        .tooltip-trigger:hover + .tooltip-content {
          .tooltip-content {
            height: auto;
            width: 230px;
          }
        }
      }

      .person-detail-info {
        margin-bottom: 10px;

        h4 {
          margin-bottom: 8px;
          color: $color07;
          font-size: $fontSizeSm;
          @include fontFamilyBold;
        }
        .input-text {
          width: 100%;
        }

        .btn-box {
          align-self: flex-end;

          .btn-bright {
            line-height: 18px;

            .clip {
              fill: $colorWhite;
              margin-right: 7px;
            }
          }
        }
        .btn-upload {
          padding: 4px 10px 0 10px;
        }

        .status-box {
          margin: 20px 0 0;
        }
      }

      .upload-file-box {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        .upload-file-item {
          display: inline-block;

          & + .upload-file-item {
            margin-left: 35px;
          }

          .delete-file {
            margin-left: 3px;
          }
        }
      }
    }

    .subjects-box {
      p {
        margin-bottom: 25px;
        line-height: 1.38;
        letter-spacing: 0.1px;
      }
      .subjects-box-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .subjects-info-box {
          display: inline-block;

          .tooltip-content {
            white-space: pre;
          }

          .info-icon {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
    }

    .notifications-box {
      .checkbox {
        white-space: normal;

        .checkbox-label {
          width: calc(100% - 20px);
        }

        a {
          margin-left: 3px;
        }
      }

      .dropdown-box {
        margin: 0 0 0 14px;
      }

      .dropdown {
        display: inline;
        height: auto;

        .lnk-dropdown {
          line-height: 15px;
        }
      }
    }
  }

  .text-danger {
    font-size: $fontSizeMd;
  }

  .desc_password.text-danger {
    font-size: $fontSizeXSm;
  }
}

.hide-scroll-profile {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.hide-scroll-profile::-webkit-scrollbar {
  display: none;
}

.profile_birth {
  .profile_birth_row {
    margin-bottom: 10px;
  }

  // .input-text__day {
  //     height: 30px;
  //     color: #3b464e;
  // }

  .lnk-dropdown {
    text-transform: capitalize;
  }

  .lnk-dropdown_month {
    width: 90px;
    margin: 0 10px;
  }

  .lnk-dropdown_year {
    width: 60px;
  }

  .lnk-dropdown_day {
    width: 54px;
  }

  .lnk-dropdown_year,
  .lnk-dropdown_day,
  .lnk-dropdown_month {
    height: 30px;
    color: #3b464e;
    border: 1px solid #c0c7ce;
    border-radius: 3px;
    line-height: 1.15;

    &:hover {
      background-color: #fff;
      text-decoration: none;
    }

    &.lnk-dropdown_disabled {
      color: $color06;
    }
  }

  .btn {
    text-align: center;
  }
}

.cr-boundary {
  width: auto;
  height: 275px !important;
  overflow: hidden;
  @include breakpoint(medium) {
    width: 360px !important;
  }
}

.container-avatar {
  width: 113px;
  height: 113px;
  margin: 0;
  position: relative;

  .container-avatar-img {
    width: 100%;
  }
}

.dropzone {
  width: 113px;
  height: 113px;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &.hide_zone .btn-change {
    display: none;
  }

  &.danger {
    border: 1px solid #fc694f;
    background-image: url("./img/icons/ava-girl.svg");
  }

  &.male {
    background-image: url("./img/icons/ava-men.svg");
  }
}

.btn-change {
  display: block;
  left: 0;
  // bottom: -28px;
  bottom: 0;
  width: 100%;
  height: 26px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s;

  // .dropzone:hover & {
  //     bottom: 0;
  // }

  span {
    display: block;
    line-height: 24px;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    text-align: center;
    pointer-events: none;
    text-transform: initial;
  }

  &__input_file {
    position: absolute;
    left: -9999px;
    top: -99999px;
  }
}

.cropper-container {
  text-align: center;
  border-radius: 3px;

  &__button {
    width: 70px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #56aafc;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #53a8ff;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    vertical-align: top;
    margin-left: 10px;
    padding: 8px 0 7px;

    &:active {
      box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.16), inset 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    }

    &_save {
      color: white;
      background-color: #53a8ff;
    }

    &:focus {
      outline: none;
    }
  }
}

.cropper-container__button-wrap {
  text-align: right;
  width: 70%;

  .btn--save {
    margin-left: 10px;
  }
}

.hide {
  visibility: hidden;
}

.avatar-container {
  width: 350px;
  height: 422px;

  h2 {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #3b464e;
    margin-bottom: 15px;
    text-align: left;
  }
}

.overview-text {
  display: block;
  color: #333;
}

.profile-noneditable-field {
  margin-left: 5px;
  color: #333;
}

.order-tab {
  &.is-writer {
    .order-tab-head {
      display: flex;
      padding: 0 30px 0 30px;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;

      .btn-close {
        right: 36px;
      }
    }

    .lnk-dropdown {
      text-align: left;
    }

    .person-info__item > div {
      display: flex;
    }
  }
}

.order-tab {
  &.modal-profile {
    position: absolute;
    z-index: 0;
    width: 650px;
    max-height: 550px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px;
    top: 14vh;
    left: 50%;
    margin-left: -600px;
    right: auto;

    .order-tab-head {
      box-shadow: none;
      border-bottom: 1px solid #e6e8eb;
      padding: 0 0 10px;

      .writer-group {
        background-size: 20px 20px;
        padding-left: 30px;
      }
    }

    .public_avatar {
      width: 113px;
      height: 113px;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      overflow: hidden;
      border-radius: 50%;
      flex: 0 1 auto;
      

      &.female {
        background-image: url("./img/icons/ava-girl.svg");
      }

      &.male {
        background-image: url("./img/icons/ava-men.svg");
      }

      &.with-avatar {
        background-image: unset;
      }

      img {
        width: 100%;
      }
    }

    .person-common-info {
      flex: 1;
      padding-left: 30px;
    }

    .order-tab-body {
      .row-devider {
        border-bottom: 1px solid #e6e8eb;
        padding: 16px 0;

        &:last-child {
          border-bottom: none;
        }

        .col2-100-110 {
          min-width: calc(100% - 110px);
        }
      }

      .order-tab-body__title {
        font-size: 12px;
        color: #9ca9b5;
        margin-bottom: 3px;
        font-weight: bold;
      }

      .order-tab-body__title-tag {
        height: 20px;
        display: inline-block;
        border-radius: 5px;
        background-color: #f2f3f5;
        color: #9ca9b5;
        font-size: 12px;
        padding: 3px 7px;
        margin-bottom: 7px;
      }

      .order-tab-body__desc {
        font-size: 15px;
        color: #637280;
        word-break: break-all;
        line-height: 20px;

        &.ellipsis {
          height: 20px;
          max-width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.ellipsis-wide {
          max-width: 460px;
        }

        &.ellipsis-narrow {
          max-width: 110px;
        }

        &.danger {
          color: #fc694f;
        }
      }
    }

    .col-center {
      text-align: center;
    }
  }
}

.wise-pk-currency-btn-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 107px;

  &--modal {
    .wise-pk-currency-btn {
      &.radiobutton {
        font-size: 16px;
        line-height: 24px;
        color: #030303;
      }
    }
  }

  .wise-pk-currency-btn {
    .radiobutton {
      font-size: 14px;
      line-height: 16px;
      color: #3B464E;
    }
  
    .radio-icon {
      margin-top: -9px!important;
    }
  }

}
